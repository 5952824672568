$(document).ready(function () {

    $('.ds-collapse-bnt').each(function() {
        if (
            $(this).attr("aria-expanded") === "true"
        ) {
            $(this).parent()
            .parent()
            .parent()
            .parent().attr("data-before", "+");
            $(this).parent()
            .parent()
            .parent()
            .parent()
                .first()
                .attr("data-before", "-");
        }
    });

    

    $(".ds-collapse-bnt").on("click", function () {
        var icon = $(this)
            .parent()
            .parent()
            .parent()
            .parent();

        
        if ($(this).attr("aria-expanded") === "true") {
            icon.attr("data-before", "+");
        } else {
            icon.attr("data-before", "-");
        }
    });

    $(".collapse-circle").on("click", function () {
        var icon = $(this)
            .parent();

        
        if ($(this).attr("aria-expanded") === "true") {
            icon.attr("data-before", "+");
        } else {
            icon.attr("data-before", "-");
        }
    });

})

