$(document).ready(function() {
  if ($(window).width() < 767) {
    $(".ds-inpage-sideNavigation button").attr("aria-expanded", "false");
    $(".collapse.show").removeClass("show");
    $("#Selected-nav-lable").text(
      $(".ds-inpage-sideNavigation .nav-item.active .nav-link").text()
    );
    $(".ds-inpage-sideNavigation .nav-item.active").addClass("d-none");
  }
  $(".ds-inpage-sideNavigation .nav-item").click(function() {
    $(".ds-inpage-sideNavigation .nav-item").removeClass("active");
    $(this).addClass("active");
    if ($(window).width() < 767) {
      $(".ds-inpage-sideNavigation .nav-item").removeClass("d-none");
      $(this).addClass("d-none");
      $("#Selected-nav-lable").text(
        $(this)
          .children()
          .text()
      );
      $(".ds-inpage-sideNavigation button").attr("aria-expanded", "false");
      $(".collapse.show").removeClass("show");
    }
  });
});
