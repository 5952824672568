$(document).ready(function() {
  $(".ds-onboarding-dropdown-header > button").click(function() {
    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
      $(this)
        .parent()
        .next()
        .slideToggle(400);
    } else {
      $(this).addClass("active");
      $(this)
        .parent()
        .next()
        .slideToggle(400);
    }
  });

  $(".ds-onboarding-dropdown-body > div").click(function() {
    $(this)
      .parent()
      .children()
      .removeClass("selected");

    $(this).addClass("selected");
    $(this)
      .parent()
      .parent()
      .children()
      .children()
      .eq(1)
      .children()
      .first()
      .text($(this).text());
  });

  $(".ds-onboarding-dropdown-body > div").on("keydown", function(e) {
    if (e.which == 13) {
      $(this)
        .parent()
        .children()
        .removeClass("selected");

      $(this).addClass("selected");
      $(this)
        .parent()
        .parent()
        .children()
        .children()
        .eq(1)
        .children()
        .first()
        .text($(this).text());
    }
  });

  //   $(document).mouseup(function(e) {
  //     $(".ds-onboarding-dropdown").each(function() {
  //       var container = $(this);

  //       if (!container.is(e.target) && container.has(e.target).length === 0) {
  //         container
  //           .children()
  //           .eq(1)
  //           .css("display", "none");

  //         container
  //           .children()
  //           .first()
  //           .children()
  //           .eq(1)
  //           .removeClass("active");
  //       }
  //     });
  //     // var container = $(".ds-onboarding-dropdown");

  //     // if (!container.is(e.target) && container.has(e.target).length === 0) {
  //     //   container
  //     //     .children()
  //     //     .eq(1)
  //     //     .css("display", "none");

  //     //   container
  //     //     .children()
  //     //     .first()
  //     //     .children()
  //     //     .eq(1)
  //     //     .removeClass("active");
  //     // }
  //   });
});
