$(document).ready(function() {
    $(".ds-header .navbar-toggler").click(function() {
    
    if($(this).hasClass("collapsed")){
        
        $(".navbar-brand").hide();
        $("#right-nav .nav-item").hide();
        $("#right-nav .mobile-hide").show();
        $("#right-nav .navbar-nav").toggleClass('flex-row');
        $("#right-nav .navbar-nav").toggleClass('flex-column');
        $("#right-nav").addClass("bottom-nav");
        $("#right-nav .navbar-nav").addClass("bottom-nav-list");   
        // $('[id^=navbarDropdown]').attr('data-toggle','modal'); 
        // $('[id^=navbarDropdown]').prop('data-target',false);
        // $('[id^=basicModal] .dropdown-menu').toggleClass('modal-content');
        // $('[id^=basicModal]').toggleClass('modal');
        //$('.dropdown-sm-header').html($('#navbarDropdown1').text());
        //$('body').css('overflow','hidden');
        $(".ds-header").addClass("mobile");
    }
    else{
        $(".navbar-brand").show();
        $("#right-nav .nav-item").show();
        $("#right-nav .mobile-hide").hide();
        $("#right-nav").removeClass("bottom-nav");
        $("#right-nav .navbar-nav").removeClass("bottom-nav-list");
        $("#right-nav .navbar-nav").toggleClass('flex-column');
        $("#right-nav .navbar-nav").toggleClass('flex-row');
        // $('[id^=navbarDropdown]').attr('data-toggle','dropdown');
        // $('[id^=navbarDropdown]').prop('data-target',true);
        // $('[id^=basicModal] .dropdown-menu').toggleClass('modal-content');
        // $('[id^=basicModal]').toggleClass('modal');
        //$('.dropdown-sm-header').html('');
        $(".ds-header").removeClass("mobile");
    }
});
});