$( document ).ready(function() {
    var length = 14;
	if ($(window).width() < 657) {
        $('.ds-navigation-card span').each(function( i,val ){
        
        var orgText = val.textContent;
        if(orgText.length>length){
        var trimmedString = orgText.substring(0, length);
        trimmedString += '...';
        val.innerHTML="";
        val.append(trimmedString);
        }
        });
    }
});