$(document).ready(function() {
  $(".ds-search-input > input[type='text']").keyup(function() {
    $(this)
      .next()
      .removeClass("d-none");

    $(this)
      .parent()
      .next()
      .removeClass("d-none");

    $(this)
      .parent()
      .children()
      .first()
      .addClass("d-none");

    if ($(this).val().length === 0) {
      $(this)
        .next()
        .addClass("d-none");

      $(this)
        .parent()
        .next()
        .addClass("d-none");

      $(this)
        .parent()
        .children()
        .first()
        .removeClass("d-none");
    }
  });
  $(".ds-search-input > .clear-btn").click(function() {
    $(this).addClass("d-none");

    $(this)
      .parent()
      .children()
      .eq(1)
      .val("");

    $(this)
      .parent()
      .next()
      .addClass("d-none");

    $(this)
      .parent()
      .children()
      .first()
      .removeClass("d-none");
  });

  $(document).mouseup(function(e) {
    var container = $(".ds-search-container");

    if (!container.is(e.target) && container.has(e.target).length === 0) {
      container
        .children()
        .eq(1)
        .addClass("d-none");

      container
        .children()
        .first()
        .children()
        .first()
        .removeClass("d-none");

      container
        .children()
        .first()
        .children()
        .eq(1)
        .val("");
      container
        .children()
        .first()
        .children()
        .eq(2)
        .addClass("d-none");
    }
  });
});
