// Returns an array of maxLength (or less) page numbers
// where a 0 in the returned array denotes a gap in the series.
// Parameters:
//   totalPages:     total number of pages
//   page:           current page
//   maxLength:      maximum size of returned array
function getPageList(totalPages, page, maxLength) {
  if (maxLength < 5) throw "maxLength must be at least 5";

  function range(start, end) {
    return Array.from(Array(end - start + 1), (_, i) => i + start);
  }

  var sideWidth = maxLength < 9 ? 1 : 2;
  var leftWidth = (maxLength - sideWidth * 2 - 3) >> 1;
  var rightWidth = (maxLength - sideWidth * 2 - 2) >> 1;
  if (totalPages <= maxLength) {
    // no breaks in list
    return range(1, totalPages);
  }
  if (page <= maxLength - sideWidth - 1 - rightWidth) {
    // no break on left of page
    console.log("left---"+range(1, maxLength - sideWidth - 1)
    .concat([0])
    .concat(range(totalPages - sideWidth + 1, totalPages)))
    return range(1, maxLength - sideWidth - 1)
      .concat([0])
      .concat(range(totalPages - sideWidth + 1, totalPages));
  }
  if (page >= totalPages - sideWidth - 1 - rightWidth) {
    // no break on right of page
    console.log("right--"+ range(1, sideWidth)
    .concat([0])
    .concat(
      range(totalPages - sideWidth - 1 - rightWidth - leftWidth, totalPages)
    ))
    return range(1, sideWidth)
      .concat([0])
      .concat(
        range(totalPages - sideWidth - 1 - rightWidth - leftWidth, totalPages)
      );
  }
  // Breaks on both sides
  return range(1, sideWidth)
    .concat([0])
    .concat(range(page - leftWidth, page + rightWidth))
    .concat([0])
    .concat(range(totalPages - sideWidth + 1, totalPages));
}

$(function() {
  // Number of items and limits the number of items per page
  var numberOfItems = 34;
  var limitPerPage = 2;
  // Total pages rounded upwards
  var totalPages = Math.ceil(numberOfItems / limitPerPage);
  // Number of buttons at the top, not counting prev/next,
  // but including the dotted buttons.
  // Must be at least 5:
  var paginationSize = 7;
  var currentPage;

  $.fn.showPage = function (whichPage) {
    if (whichPage < 1 || whichPage > totalPages) return false;
    currentPage = whichPage;
    // $("#jar .content")
    //   .hide()
    //   .slice((currentPage - 1) * limitPerPage, currentPage * limitPerPage)
    //   .show();
    // Replace the navigation items (not prev/next):
    $(".pagination li").slice(1, -1).remove();
    getPageList(totalPages, currentPage, paginationSize).forEach(item => {
      if(currentPage == 1 ){
        $('#previous-page').hide();
      }
      else if(currentPage == totalPages ){
        $('#next-page').hide();
      }
      else{
        $('#previous-page').show();
        $('#next-page').show();
      }
      $("<li>")
        .addClass(
          "page-item "
        )
        .append(
          $("<a>")
            .addClass("page-link btn btn-secondary btn-text " +
            (item === currentPage ? " selected" : ""))
            .attr({
              href: "javascript:void(0)"
            })
            .text(item || "...")
        )
        .insertBefore("#next-page");
    });
    return true;
  }

  // Include the prev/next buttons:
  $(".pagination").append(
    $("<li>").addClass("page-item previous").attr({ id: "previous-page" }).append(
      $("<a>")
        .addClass("align-items-center btn btn-icon btn-icon-left btn-secondary d-flex justify-content-between")
        .attr({
          href: "javascript:void(0)"
        })
        .append($("<i>").addClass("ds-chevron-left")).append($("<span>").text("Previous"))
    ),
    $("<li>").addClass("page-item next").attr({ id: "next-page" }).append(
      $("<a>")
        .addClass("align-items-center btn btn-icon btn-icon-right btn-secondary d-flex justify-content-between")
        .attr({
          href: "javascript:void(0)"
        })
        .append($("<span>").text("Next")).append($("<i>").addClass("ds-chevron-right"))
    )
  );
  // Show the page links
  

  // Use event delegation, as these items are recreated later
  $(
    document
  ).on("click", ".pagination li:not(.active)", function() {
    if($(this).find('a').text() == '...'){
      return null;
    }
    return $.fn.showPage(+$(this).text());
  });
  $("#next-page").on("click", function() {
    return $.fn.showPage(currentPage + 1);
  });

  $("#previous-page").on("click", function() {
    return $.fn.showPage(currentPage - 1);
  });
  // $(".pagination").on("click", function() {
  //   $("html,body").animate({ scrollTop: 0 }, 0);
  // });
});
