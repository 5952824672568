$(".dropdown-menu a").click(function() {
  $(this)
    .find("a")
    .removeClass("active");
  $(this).addClass("active");
  $(this)
    .parents(".dropdown")
    .find(".btn")
    .html($(this).text() + '<i class="ds-chevron-down"></i>');
  $(this)
    .parents(".dropdown")
    .find(".btn")
    .val($(this).data("value"));
});
