$(document).ready(function() {
  $(".ds-footer-section > button").click(function() {
  if ($(window).width() < 657) {
    if ($(this).hasClass("active")) {
      $(this)
        .children()
        .css({ transform: "rotate(0deg)" });

      $(this)
        .next()
        .slideToggle(400);

      $(this).removeClass("active");
    } else {
      $(this)
        .children()
        .css({ transform: "rotate(180deg)" });

      $(this)
        .next()
        .slideToggle(400);

      $(this).addClass("active");
    }
  }
});

$(".ds-feedback-header > .anser > .btn-no").click(function() {
  $(this)
    .parent()
    .parent()
    .next()
    .css({ display: "block" });
  $('.feedback-no').show();
  $('.feedback-yes').hide();
  $(this)
    .parent()
    .parent()
    .css({ display: "none" });
});

$('#isComment').click(function (){ 
  if ($(this).is(':checked')) { 
     $('.feedback-input').removeClass('d-none');
  }else{
    $('.feedback-input').addClass('d-none');
  }
});	


$(".ds-feedback-header > .anser > .btn-yes").click(function() {
  $(this)
    .parent()
    .parent()
    .next()
    .css({ display: "block" });
  $('.feedback-yes').show();
  $('.feedback-no').hide();
  $(this)
    .parent()
    .parent()
    .css({ display: "none" });
});

$(".ds-feedback-body > .close-btn").click(function() {
  $(this)
    .parent()
    .css({ display: "none" });

  $(this)
    .parent()
    .parent()
    .children()
    .first()
    .css({ display: "flex" });
  $('.feedback-input').addClass('d-none');
});
});